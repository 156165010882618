import { AxiosResponse } from 'axios'
import { mulesoftApiClient } from 'infrastructure/mulesoft-client'
import environment from 'configuration/environment'
import { TokenType } from 'infrastructure/token'
import SessionProvider from 'session'
import { getClientIdFromToken } from 'utils/token-decoder'

const serviceUrl = `${environment.apimUrl}/clientlogin/legacy/validate-login`

// Request and Response from ClientLogin_API_Consumer_Document_V1.10.docx
type LoginApiRequest = {
  username: string
  password: string
}

export type LoginApiResponse = {
  access_token: string
  refresh_token: string
  scope: string
  expires_in: number
  token_type: string
}

class LoginService {
  async login(userName: string, password: string): Promise<LoginApiResponse> {
    const requestPayload: LoginApiRequest = {
      username: userName,
      password: password,
    }

    SessionProvider.clearUserSession()

    return mulesoftApiClient
      .post<LoginApiRequest, AxiosResponse<LoginApiResponse>>(
        serviceUrl,
        requestPayload,
        {
          tokenType: TokenType.Application,
        },
        'POST /clientLogin/validate-login'
      )
      .then((response) => {
        const { access_token: AccessToken, refresh_token: RefreshToken } = response.data

        SessionProvider.setUserSession({
          accessToken: AccessToken,
          refreshToken: RefreshToken,
          clientId: getClientIdFromToken(AccessToken),
        })

        return response.data
      })
  }
}

export default new LoginService()
