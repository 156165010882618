import * as Sentry from '@sentry/node'
import { ACTION_TYPE } from 'actions/mobileApp/types'
import { AxiosError, AxiosResponse } from 'axios'
import environment from 'configuration/environment'
import { ROUTE } from 'configuration/routes'
import { postMessageSession } from 'hooks/usePostMessage'
import { MulesoftApiClient } from 'infrastructure/mulesoft-client'
import requestContext from 'infrastructure/request-context'
import { IAppState } from 'reducers'
import sessionProvider, { UserSession } from 'session/index'
import { resolveSentrySeverity } from 'utils/sentrySeverity'
import { getRegion } from '../utils/getRegion'
const serviceUrl = `${environment.apimUrl}/clientlogin/legacy/refresh-client-token`

type ClientTokenApiRequest = {
  grant_type: string
  client_id: string
  client_secret: string
  refresh_token: string
}

type ClientTokenApiResponse = {
  access_token: string
  refresh_token: string
  scope: string
  expires_in: number
  token_type: string
}

class ClientToken {
  refresh(mulesoftClient: MulesoftApiClient): Promise<UserSession | undefined> {
    const userSession = sessionProvider.getUserSession()

    Sentry.addBreadcrumb({
      category: 'Account Creation',
      message: 'Refreshing client token',
      level: Sentry.Severity.Info,
    })

    return mulesoftClient
      .post<ClientTokenApiRequest, AxiosResponse<ClientTokenApiResponse>>(
        serviceUrl,
        {
          grant_type: 'refresh_token',
          client_id: environment.auth0ClientId,
          client_secret: environment.auth0Secret,
          refresh_token: userSession.refreshToken,
        },
        {},
        'POST /clientLogin/refresh-client-token'
      )
      .then((response) => {
        const result: UserSession = {
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
          clientId: userSession.clientId,
        }

        const state = requestContext.store.getState() as IAppState

        if (state.authentication.isMobile) {
          const payload = postMessageSession(
            response.data.access_token,
            response.data.refresh_token,
            window.location.pathname
          )
          if (requestContext.store) {
            requestContext.store.dispatch({
              type: ACTION_TYPE.POST_MESSAGE,
              payload,
            })
          }
        }
        sessionProvider.setUserSession(result)
        return result
      })
      .catch((error: AxiosError) => {
        Sentry.captureEvent({
          message: 'Failed to refresh client token',
          level: resolveSentrySeverity(error.response?.status),
          tags: {
            service: 'Mulesoft',
            endpoint: serviceUrl,
            whichend: process.env.NEXT_IS_SERVER === 'true' ? 'back' : 'front',
            region: getRegion(),
          },
        })

        if (requestContext.store) {
          requestContext.store.dispatch({ type: 'CHR/RESET' })
        }

        if (process.env.NEXT_IS_SERVER === 'true') {
          if (requestContext.res) {
            requestContext.res.writeHead(302, {
              Location: ROUTE.HOME,
            })
            requestContext.res.end()
          }
        } else {
          window.location.assign(ROUTE.HOME)
        }

        return undefined
      })
  }
}

export default new ClientToken()
