import classNames from 'classnames'
import { environment } from 'configuration'
import styles from './index.module.scss'
import { Icon } from 'components/Icon'
import { TranslationKeyPrefix, useTranslations } from 'hooks/useTranslations'
import { useSelector } from 'react-redux'
import { IAppState } from 'reducers'
import { appendLangParamToUrl } from 'utils/urls'
import { SupportedLanguages } from 'configuration/translations'

export interface MinimalHeaderProps {
  hideWhenLoggedIn: boolean
  userLoggedIn: boolean
}

export const MinimalHeader = ({ hideWhenLoggedIn, userLoggedIn }: MinimalHeaderProps) => {
  const { getTranslationLiteral: tl } = useTranslations(TranslationKeyPrefix.AccountCreation)
  const { language } = useSelector((state: IAppState) => state.authentication)

  return (
    <div
      className={classNames(styles.header, {
        [styles.loggedIn]: userLoggedIn && !hideWhenLoggedIn,
      })}
    >
      <a
        className={classNames(styles.logo, {
          [styles.loggedOut]: !userLoggedIn || hideWhenLoggedIn,
        })}
        data-track="|header_logo"
        href={appendLangParamToUrl(environment.dotComUrl, language)}
      >
        <span className={styles.visuallyHidden}>{tl('christies')}</span>
        <Icon icon={language === SupportedLanguages.EN ? 'logo' : 'logoChinese'} variant="dark" />
      </a>
      {userLoggedIn && !hideWhenLoggedIn && (
        <a
          className={styles.header__account}
          data-track="|header_account"
          href={`${environment.dotComUrl}/mychristies/activities.aspx?activitytype=wc&submenutapped=true`}
          aria-label={tl('header.my_account')}
        >
          <div className={styles.header__user}>
            <Icon icon="user" variant="dark" />
          </div>
        </a>
      )}
      {/* 
          Mimecast Brand Exploit Web Agent Script AKA Ping Tracker integration
          Full context: https://christiestech.atlassian.net/wiki/spaces/CT/pages/2853798123/Ping+Tracker+Implementation
        */}
      <script
        async
        src="https://static.srcspot.com/libs/ida.js"
        type="application/javascript"
      ></script>
    </div>
  )
}
