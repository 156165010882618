import { environment } from 'configuration'
import React from 'react'
import styles from './index.module.scss'
import { ExternalLink } from 'components/ExternalLink'
import { TranslationKeyPrefix, useTranslations } from 'hooks/useTranslations'
interface PageShellProps {
  pageData: {
    status: number
    title: string
    subtitle: string
  }
}

export function StatusPageShell({ pageData: { status, title, subtitle } }: PageShellProps) {
  const { getTranslation: t, getTranslationLiteral: tl } = useTranslations(
    TranslationKeyPrefix.AccountCreation
  )
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 offset-lg-3 col-sm-12">
          <div className={styles.page}>
            <p className={styles.page__number}>{status}</p>
            <h1 data-testid="title">{title}</h1>
            <p data-testid="subtitle" className={styles.page__link}>
              {subtitle}
            </p>
            <div className={styles.page__bottom}>
              <ExternalLink
                className={styles.page__bottom_button}
                track="back_to_christies"
                href={environment.dotComUrl}
                target="_self"
                data-testid="go-to-christies"
              >
                {tl('go_to_christies')}
              </ExternalLink>
              <p data-testid="contact-client-services">{t('or_contact_client_services')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
