import { AxiosResponse } from 'axios'
import environment from 'configuration/environment'
import { mulesoftApiClient } from 'infrastructure/mulesoft-client'
import { DOCUMENT_TYPE, KYC_DOCUMENT_STATUS } from 'configuration'
import { TokenType } from 'infrastructure/token'

const getDocumentUploadServiceUrl = (documentType: DOCUMENT_TYPE): string =>
  `${environment.apimUrl}/clientlogin/legacy/verification-documents?document_type=${documentType}`

const createFormData = (document: File) => {
  const formData = new FormData()
  formData.append('UploadedImage', document)
  return formData
}

export type DocumentUploadApiResponse = {
  ResponseCode: string
  ResponseMessage: string
  valid_country_document?: KYC_DOCUMENT_STATUS | null
  valid_date_of_birth?: KYC_DOCUMENT_STATUS | null
  valid_first_name?: KYC_DOCUMENT_STATUS | null
  valid_id_document?: KYC_DOCUMENT_STATUS | null
  valid_last_name?: KYC_DOCUMENT_STATUS | null
  valid_poa_document?: KYC_DOCUMENT_STATUS | null
  summary?: KYC_DOCUMENT_STATUS | null
}

class DocumentUpload {
  upload(file: File, documentType: DOCUMENT_TYPE): Promise<DocumentUploadApiResponse> {
    return mulesoftApiClient
      .post<FormData, AxiosResponse<DocumentUploadApiResponse>>(
        getDocumentUploadServiceUrl(documentType),
        createFormData(file),
        {
          tokenType: TokenType.User,
          headers: {
            'Content-Type': 'multipart/form-data',
            accept_version: 'V2',
          },
        },
        'POST /clientLogin/verification-documents'
      )
      .then((response) => response.data)
  }
}

export default new DocumentUpload()
