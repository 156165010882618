import * as Sentry from '@sentry/node'
import { AxiosResponse } from 'axios'
import environment from 'configuration/environment'
import { MulesoftApiClient } from 'infrastructure/mulesoft-client'
import { TokenType } from 'infrastructure/token'
import sessionProvider from 'session/index'

const serviceUrl = `${environment.auth0Url}/oauth/token`

type ApplicationAccessTokenApiRequest = {
  grant_type: string
  audience: string
  client_id: string
  client_secret: string
}

type ApplicationAccessTokenApiResponse = {
  access_token: string
  expires_in: number
  token_type: string
}

export type ApplicationAccessTokenResponse = {
  AccessToken: string
  AccessTokenSecondsTillExpiry: number
  TokenType: TokenType
}

class ApplicationToken {
  refresh(mulesoftClient: MulesoftApiClient): Promise<ApplicationAccessTokenResponse> {
    Sentry.addBreadcrumb({
      category: 'Account Creation',
      message: 'Refreshing application token',
      level: Sentry.Severity.Info,
    })

    return mulesoftClient
      .post<ApplicationAccessTokenApiRequest, AxiosResponse<ApplicationAccessTokenApiResponse>>(
        serviceUrl,
        {
          grant_type: 'client_credentials',
          audience: environment.auth0Audience,
          client_id: environment.auth0ClientId,
          client_secret: environment.auth0Secret,
        },
        {},
        'POST /oauth/token'
      )
      .then((response) => {
        const result: ApplicationAccessTokenResponse = {
          AccessToken: response.data.access_token,
          AccessTokenSecondsTillExpiry: response.data.expires_in,
          TokenType: TokenType.Application,
        }

        // The moment we get a new token back from the service we store it in the session provider
        sessionProvider.setApplicationSession(result.AccessToken)
        return result
      })
  }
}

export default new ApplicationToken()
