import { AxiosResponse } from 'axios'
import environment from 'configuration/environment'
import { mulesoftApiClient } from 'infrastructure/mulesoft-client'
import { TokenType } from 'infrastructure/token'
import UsZipcodeLookup from 'services/us-zipcode-lookup'

const serviceUrl = `${environment.apimUrl}/clientlogin/legacy/contact-details`

type ContactDetailsApiRequest = {
  dob_month: string
  dob_day: string
  dob_year: string
  address_street_1: string
  address_street_2: string
  address_street_3?: string
  address_city: string
  address_state?: string
  address_province_county: string
  address_postalcode: string
  address_countryid: string
  primary_phonenumber: string
  primary_extension?: string // unused in this app, and optional on the endpoint.
  primary_phonecountryid: string
  primary_ismobile: string
  national_identification_number?: string
  address_verified: string
}

export type ContactDetailsPayload = {
  dateOfBirth: Date
  address: {
    street1: string
    street2: string
    street3?: string
    city: string
    state: string
    provinceCounty: string
    postalCode: string
    countryId: string
    verified: boolean
  }
  phone: {
    number: string
    isMobile: boolean
    countryId: string
  }
  codiceFiscale: string
}

export type ContactDetailsApiResponse = {
  message: string
  kyc_status: string
  account_number: string
  name: string
  email: string
  client_guid: string
  client_id: string
}

class ContactDetails {
  save(contactDetails: ContactDetailsPayload): Promise<ContactDetailsApiResponse> {
    const payload: ContactDetailsApiRequest = {
      dob_day: contactDetails.dateOfBirth.getDate().toString(),
      dob_month: (contactDetails.dateOfBirth.getMonth() + 1).toString(), // getMonth is zero based,
      dob_year: contactDetails.dateOfBirth.getUTCFullYear().toString(),
      address_street_1: contactDetails.address.street1,
      address_street_2: contactDetails.address.street2,
      address_street_3: contactDetails.address.street3,
      address_city: contactDetails.address.city,
      address_province_county: contactDetails.address.provinceCounty,
      address_postalcode: contactDetails.address.postalCode,
      address_countryid: contactDetails.address.countryId,
      primary_phonenumber: contactDetails.phone.number,
      primary_ismobile: contactDetails.phone.isMobile ? 'Y' : 'N',
      primary_phonecountryid: contactDetails.phone.countryId,
      address_verified: contactDetails.address.verified ? 'Y' : 'N',
    }

    if (contactDetails.address.state) {
      payload.address_state = contactDetails.address.state
    }

    if (contactDetails.codiceFiscale) {
      payload.national_identification_number = contactDetails.codiceFiscale
    }

    if (payload.address_countryid === '1') {
      return UsZipcodeLookup.get(payload.address_postalcode.split('-')[0]).then((result) => {
        if (result.zipcode_info.length > 0) {
          const primaryCounty = result.zipcode_info.find((item) => item.is_main_county)

          if (primaryCounty) {
            payload.address_province_county = primaryCounty.county
          }

          return mulesoftApiClient
            .post<ContactDetailsApiRequest, AxiosResponse<ContactDetailsApiResponse>>(
              serviceUrl,
              payload,
              { tokenType: TokenType.User, headers: { accept_version: 'V2' } },
              'POST /clientLogin/contact-details'
            )
            .then((response) => response.data)
        } else {
          throw 'Could not get county for US zipcode'
        }
      })
    }

    return mulesoftApiClient
      .post<ContactDetailsApiRequest, AxiosResponse<ContactDetailsApiResponse>>(
        serviceUrl,
        payload,
        { tokenType: TokenType.User, headers: { accept_version: 'V2' } },
        'POST /clientLogin/contact-details'
      )
      .then((response) => response.data)
  }
}

export default new ContactDetails()
