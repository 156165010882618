import environment from 'configuration/environment'
import { TranslationKeyPrefix, useTranslations } from 'hooks/useTranslations'
import Head from 'next/head'
import React, { ReactElement } from 'react'

export const MetaTags = (): ReactElement => {
  const { getTranslationLiteral: tl } = useTranslations(TranslationKeyPrefix.Meta)
  return (
    <Head>
      <meta data-testid="meta-description" name="description" content={tl('description')} />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="keywords" content="christie's account, my christie's" />
      <meta httpEquiv="Content-Language" content="en-us" />
      <meta name="classification" content="Internet" />
      <meta name="distribution" content="Global" />
      <meta name="rating" content="General" />
      <meta name="copyright" content="Christies" /> {/* from "Christie?" */}
      <meta name="author" content="Christies" /> {/* from "Christie?" */}
      <meta name="revisit-after" content="2 days" />
      <meta httpEquiv="reply-to" content="Christies" /> {/* from "Christie?" */}
      <meta name="robots" content="ALL" />
      <meta name="robots" content="noarchive" />
      <link
        rel="icon"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/favicon.ico`}
        sizes="any"
      />
      <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-icon-57x57.png`}
        sizes="57x57"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-icon-60x60.png`}
        sizes="60x60"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-icon-72x72.png`}
        sizes="72x72"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-icon-76x76.png`}
        sizes="76x76"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-icon-114x114.png`}
        sizes="114x114"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-icon-120x120.png`}
        sizes="120x120"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-icon-144x144.png`}
        sizes="144x144"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-icon-152x152.png`}
        sizes="152x152"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-icon-167x167.png`}
        sizes="167x167"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-icon-180x180.png`}
        sizes="180x180"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-icon-1024x1024.png`}
        sizes="1024x1024"
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 320px) and (min-height: 480px) and (-webkit-device-pixel-ratio: 1)"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-startup-image-320x460.png`}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 320px) and (min-height: 480px) and (-webkit-device-pixel-ratio: 2)"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-startup-image-640x920.png`}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 320px) and (min-height: 568px) and (-webkit-device-pixel-ratio: 2)"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-startup-image-640x1096.png`}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 375px) and (min-height: 667px) and (-webkit-device-pixel-ratio: 2)"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-startup-image-750x1294.png`}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 414px) and (min-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-startup-image-1182x2208.png`}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 414px) and (min-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-startup-image-1242x2148.png`}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 414px) and (min-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-startup-image-748x1024.png`}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 768px) and (min-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-startup-image-768x1004.png`}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 768px) and (min-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-startup-image-1496x2048.png`}
      />
      <link
        rel="apple-touch-startup-image"
        media="(min-width: 768px) and (min-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/apple-touch-startup-image-1536x2008.png`}
      />
      <link
        rel="icon"
        type="image/png"
        href={`${environment.dotComUrl}/Assets/Discovery.Project.Website/V2023/favicons/coast-228x228.png`}
      />
    </Head>
  )
}
