import { ROUTE, navigationLinks, type Links } from './routes'
import environment, { BUILD_CONFIGURATION } from './environment'
import { MARKETING_OPTIONS } from './marketing'
import { DOCUMENT_TYPE, KYC_STATUS, KYC_DOCUMENT_STATUS } from './kyc'
import { DEVICE_OPTIONS } from './devices'
import { MESSAGE_TYPE } from './mobileApp'

export {
  ROUTE,
  environment,
  BUILD_CONFIGURATION,
  MARKETING_OPTIONS,
  DOCUMENT_TYPE,
  KYC_STATUS,
  KYC_DOCUMENT_STATUS,
  DEVICE_OPTIONS,
  MESSAGE_TYPE,
  navigationLinks,
  Links,
}
