import { env } from 'next-runtime-env'

export const BUILD_CONFIGURATION = {
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
  TEST: 'test',
}

export interface IEnvironmentConfig {
  name: string
  debug: string
  auth0Url: string
  auth0Audience: string
  auth0ClientId: string
  auth0Secret: string
  cookieDomain: string
  dotComUrl: string
  dotComApiKey: string
  thisAppUrl: string
  dslAssetsUrl: string
  adobeContainerUrl: string
  hCaptchaKey: string
  buildId: string
  gbgProfileGlobal: string
  /**
   * A number between 0 and 1, controlling the percentage chance a given transaction will be sent to Sentry.
   * https://docs.sentry.io/platforms/javascript/guides/ember/configuration/options/#tracing-options
   */
  tracesSampleRate: number
  apimUrl: string
  afdUrl: string
}

export interface IAllEnvironmentConfig {
  PRODUCTION_EN: IEnvironmentConfig
  STAGING_EN: IEnvironmentConfig
  INTEGRATION_EN: IEnvironmentConfig
  TEST_EN: IEnvironmentConfig
  DEV_EN: IEnvironmentConfig
  PRODUCTION_CN: IEnvironmentConfig
  STAGING_CN: IEnvironmentConfig
  INTEGRATION_CN: IEnvironmentConfig
  TEST_CN: IEnvironmentConfig
  DEV_CN: IEnvironmentConfig
  [key: string]: IEnvironmentConfig
}

export const CHRISTIES_ENVIRONMENT_CONFIG: IAllEnvironmentConfig = {
  PRODUCTION_EN: {
    name: 'production_EN',
    debug: env('NEXT_PUBLIC_DEBUG') || 'false',
    auth0Url: 'https://christies-account.us.auth0.com',
    auth0Audience: 'https://christies.com/general-service-api',
    auth0ClientId: env('NEXT_PUBLIC_CLIENT_ID') as string,
    auth0Secret: env('NEXT_PUBLIC_CLIENT_SECRET') as string,
    cookieDomain: '.christies.com',
    dotComUrl: 'https://www.christies.com',
    dotComApiKey: env('NEXT_PUBLIC_DOT_COM_API_KEY') as string,
    thisAppUrl: 'https://signup.christies.com',
    dslAssetsUrl: 'https://dsl.assets.christies.com',
    hCaptchaKey: env('NEXT_PUBLIC_SITE_KEY') as string,
    buildId: process.env.CONFIG_BUILD_ID as string,
    adobeContainerUrl: '//assets.adobedtm.com/4f105c1434ad/05fddbc767bb/launch-1b477ca5fd6e.min.js',
    gbgProfileGlobal: env('NEXT_PUBLIC_GBG_PROFILE_GUID_GLOBAL') as string,
    tracesSampleRate: 0.5,
    afdUrl: 'https://api-prod.christies.com',
    apimUrl: 'https://apim.christies.com',
  },
  STAGING_EN: {
    name: 'staging_EN',
    debug: env('NEXT_PUBLIC_DEBUG') || 'false',
    auth0Url: 'https://uat-christies-account.auth0.com',
    auth0Audience: 'https://uat.christies.com/general-service-api',
    auth0ClientId: env('NEXT_PUBLIC_CLIENT_ID') as string,
    auth0Secret: env('NEXT_PUBLIC_CLIENT_SECRET') as string,
    cookieDomain: '.christies.com',
    dotComUrl: 'https://staging.christies.com',
    dotComApiKey: env('NEXT_PUBLIC_DOT_COM_API_KEY') as string,
    dslAssetsUrl: 'https://dsl.assets.christies.com',
    thisAppUrl: 'https://signup-uat.christies.com',
    hCaptchaKey: env('NEXT_PUBLIC_SITE_KEY') as string,
    buildId: process.env.CONFIG_BUILD_ID as string,
    adobeContainerUrl:
      '//assets.adobedtm.com/4f105c1434ad/05fddbc767bb/launch-334804b1bc3f-staging.min.js',
    gbgProfileGlobal: env('NEXT_PUBLIC_GBG_PROFILE_GUID_GLOBAL') as string,
    tracesSampleRate: 1,
    afdUrl: 'https://api-nonprod.christies.com/uat',
    apimUrl: 'https://devapim.christies.com/uat',
  },
  INTEGRATION_EN: {
    name: 'integration_EN',
    debug: env('NEXT_PUBLIC_DEBUG') || 'false',
    auth0Url: 'https://sit-christies-account.us.auth0.com',
    auth0Audience: 'https://sit.christies.com/general-service-api',
    auth0ClientId: env('NEXT_PUBLIC_CLIENT_ID') as string,
    auth0Secret: env('NEXT_PUBLIC_CLIENT_SECRET') as string,
    cookieDomain: '.christies.com',
    dotComUrl: 'https://integration.christies.com',
    dotComApiKey: env('NEXT_PUBLIC_DOT_COM_API_KEY') as string,
    thisAppUrl: 'https://signup-sit.christies.com',
    dslAssetsUrl: 'https://dsl.assets.christies.com',
    hCaptchaKey: env('NEXT_PUBLIC_SITE_KEY') as string,
    buildId: process.env.CONFIG_BUILD_ID as string,
    adobeContainerUrl:
      '//assets.adobedtm.com/4f105c1434ad/05fddbc767bb/launch-334804b1bc3f-staging.min.js',
    gbgProfileGlobal: env('NEXT_PUBLIC_GBG_PROFILE_GUID_GLOBAL') as string,
    tracesSampleRate: 1,
    afdUrl: 'https://api-nonprod.christies.com/sit',
    apimUrl: 'https://devapim.christies.com/sit',
  },
  TEST_EN: {
    name: 'test_EN',
    debug: env('NEXT_PUBLIC_DEBUG') || 'false',
    auth0Url: 'https://sit-christies-account.us.auth0.com',
    auth0Audience: 'https://sit.christies.com/general-service-api',
    auth0ClientId: env('NEXT_PUBLIC_CLIENT_ID') as string,
    auth0Secret: env('NEXT_PUBLIC_CLIENT_SECRET') as string,
    cookieDomain: '.christies.com',
    dotComUrl: 'https://integration.christies.com',
    dotComApiKey: env('NEXT_PUBLIC_DOT_COM_API_KEY') as string,
    thisAppUrl: 'https://signup-test.christies.com',
    dslAssetsUrl: 'https://dsl.assets.christies.com',
    hCaptchaKey: env('NEXT_PUBLIC_SITE_KEY') as string,
    buildId: process.env.CONFIG_BUILD_ID as string,
    adobeContainerUrl:
      '//assets.adobedtm.com/4f105c1434ad/05fddbc767bb/launch-334804b1bc3f-staging.min.js',
    gbgProfileGlobal: env('NEXT_PUBLIC_GBG_PROFILE_GUID_GLOBAL') as string,
    tracesSampleRate: 1,
    afdUrl: 'https://api-nonprod.christies.com/dev',
    apimUrl: 'https://devapim.christies.com/sit',
  },
  DEV_EN: {
    name: 'development_EN',
    debug: env('NEXT_PUBLIC_DEBUG') || 'true',
    auth0Url: 'https://uat-christies-account.auth0.com',
    auth0Audience: 'https://uat.christies.com/general-service-api',
    auth0ClientId: env('NEXT_PUBLIC_CLIENT_ID') as string,
    auth0Secret: env('NEXT_PUBLIC_CLIENT_SECRET') as string,
    cookieDomain: '.christies.com',
    dotComUrl: 'https://staging.christies.com',
    dotComApiKey: env('NEXT_PUBLIC_DOT_COM_API_KEY') as string,
    thisAppUrl: 'https://dev.christies.com',
    dslAssetsUrl: 'https://dsl.assets.christies.com',
    hCaptchaKey: env('NEXT_PUBLIC_SITE_KEY') as string,
    buildId: 'DEV_BUILD',
    adobeContainerUrl:
      '//assets.adobedtm.com/4f105c1434ad/05fddbc767bb/launch-334804b1bc3f-staging.min.js',
    gbgProfileGlobal: env('NEXT_PUBLIC_GBG_PROFILE_GUID_GLOBAL') as string,
    tracesSampleRate: 1,
    afdUrl: 'https://api-nonprod.christies.com/uat',
    apimUrl: 'https://devapim.christies.com/uat',
  },
  PRODUCTION_CN: {
    name: 'production_CN',
    debug: env('NEXT_PUBLIC_DEBUG') || 'false',
    auth0Url: 'https://christies-account.us.auth0.com',
    auth0Audience: 'https://christies.com/general-service-api',
    auth0ClientId: env('NEXT_PUBLIC_CLIENT_ID') as string,
    auth0Secret: env('NEXT_PUBLIC_CLIENT_SECRET') as string,
    cookieDomain: '.christies.com.cn',
    dotComUrl: 'https://www.christies.com.cn',
    dotComApiKey: env('NEXT_PUBLIC_DOT_COM_API_KEY') as string,
    thisAppUrl: 'https://signup.christies.com.cn',
    dslAssetsUrl: 'https://dsl.assets.christies.com.cn',
    hCaptchaKey: env('NEXT_PUBLIC_SITE_KEY') as string,
    buildId: process.env.CONFIG_BUILD_ID as string,
    adobeContainerUrl: '//assets.adobedtm.com/4f105c1434ad/05fddbc767bb/launch-1b477ca5fd6e.min.js',
    gbgProfileGlobal: env('NEXT_PUBLIC_GBG_PROFILE_GUID_GLOBAL') as string,
    tracesSampleRate: 0.5,
    afdUrl: 'https://api-prod.christies.com.cn',
    apimUrl: 'https://apim.christies.com',
  },
  STAGING_CN: {
    name: 'staging_CN',
    debug: env('NEXT_PUBLIC_DEBUG') || 'false',
    auth0Url: 'https://uat-christies-account.auth0.com',
    auth0Audience: 'https://uat.christies.com/general-service-api',
    auth0ClientId: env('NEXT_PUBLIC_CLIENT_ID') as string,
    auth0Secret: env('NEXT_PUBLIC_CLIENT_SECRET') as string,
    cookieDomain: '.christies.com.cn',
    dotComUrl: 'https://staging.christies.com.cn',
    dotComApiKey: env('NEXT_PUBLIC_DOT_COM_API_KEY') as string,
    thisAppUrl: 'https://signup-uat.christies.com.cn',
    dslAssetsUrl: 'https://dsl.assets.christies.com.cn',
    hCaptchaKey: env('NEXT_PUBLIC_SITE_KEY') as string,
    buildId: process.env.CONFIG_BUILD_ID as string,
    adobeContainerUrl:
      '//assets.adobedtm.com/4f105c1434ad/05fddbc767bb/launch-334804b1bc3f-staging.min.js',
    gbgProfileGlobal: env('NEXT_PUBLIC_GBG_PROFILE_GUID_GLOBAL') as string,
    tracesSampleRate: 0.5,
    afdUrl: 'https://api-nonprod.christies.com.cn/uat',
    apimUrl: 'https://devapim.christies.com/uat',
  },
  INTEGRATION_CN: {
    name: 'integration_CN',
    debug: env('NEXT_PUBLIC_DEBUG') || 'false',
    auth0Url: 'https://sit-christies-account.us.auth0.com',
    auth0Audience: 'https://sit.christies.com/general-service-api',
    auth0ClientId: env('NEXT_PUBLIC_CLIENT_ID') as string,
    auth0Secret: env('NEXT_PUBLIC_CLIENT_SECRET') as string,
    cookieDomain: '.christies.com.cn',
    dotComUrl: 'https://integration.christies.com.cn',
    dotComApiKey: env('NEXT_PUBLIC_DOT_COM_API_KEY') as string,
    thisAppUrl: 'https://signup-sit.christies.com.cn',
    dslAssetsUrl: 'https://dsl.assets.christies.com.cn',
    hCaptchaKey: env('NEXT_PUBLIC_SITE_KEY') as string,
    buildId: process.env.CONFIG_BUILD_ID as string,
    adobeContainerUrl:
      '//assets.adobedtm.com/4f105c1434ad/05fddbc767bb/launch-334804b1bc3f-staging.min.js',
    gbgProfileGlobal: env('NEXT_PUBLIC_GBG_PROFILE_GUID_GLOBAL') as string,
    tracesSampleRate: 1,
    afdUrl: 'https://api-nonprod.christies.com.cn/sit',
    apimUrl: 'https://devapim.christies.com/sit',
  },
  TEST_CN: {
    name: 'test_CN',
    debug: env('NEXT_PUBLIC_DEBUG') || 'false',
    auth0Url: 'https://sit-christies-account.us.auth0.com',
    auth0Audience: 'https://sit.christies.com/general-service-api',
    auth0ClientId: env('NEXT_PUBLIC_CLIENT_ID') as string,
    auth0Secret: env('NEXT_PUBLIC_CLIENT_SECRET') as string,
    cookieDomain: '.christies.com.cn',
    dotComUrl: 'https://integration.christies.com.cn',
    dotComApiKey: env('NEXT_PUBLIC_DOT_COM_API_KEY') as string,
    thisAppUrl: 'https://signup-test.christies.com.cn',
    dslAssetsUrl: 'https://dsl.assets.christies.com.cn',
    hCaptchaKey: env('NEXT_PUBLIC_SITE_KEY') as string,
    buildId: process.env.CONFIG_BUILD_ID as string,
    adobeContainerUrl:
      '//assets.adobedtm.com/4f105c1434ad/05fddbc767bb/launch-334804b1bc3f-staging.min.js',
    gbgProfileGlobal: env('NEXT_PUBLIC_GBG_PROFILE_GUID_GLOBAL') as string,
    tracesSampleRate: 1,
    afdUrl: 'https://api-nonprod.christies.com.cn/dev',
    apimUrl: 'https://devapim.christies.com/sit',
  },
  DEV_CN: {
    name: 'development_CN',
    debug: env('NEXT_PUBLIC_DEBUG') || 'true',
    auth0Url: 'https://uat-christies-account.auth0.com',
    auth0Audience: 'https://uat.christies.com/general-service-api',
    auth0ClientId: env('NEXT_PUBLIC_CLIENT_ID') as string,
    auth0Secret: env('NEXT_PUBLIC_CLIENT_SECRET') as string,
    cookieDomain: '.christies.com.cn',
    dotComUrl: 'https://staging.christies.com.cn',
    dotComApiKey: env('NEXT_PUBLIC_DOT_COM_API_KEY') as string,
    thisAppUrl: 'https://dev.christies.com.cn',
    dslAssetsUrl: 'https://dsl.assets.christies.com.cn',
    hCaptchaKey: env('NEXT_PUBLIC_SITE_KEY') as string,
    buildId: 'DEV_BUILD',
    adobeContainerUrl:
      '//assets.adobedtm.com/4f105c1434ad/05fddbc767bb/launch-334804b1bc3f-staging.min.js',
    gbgProfileGlobal: env('NEXT_PUBLIC_GBG_PROFILE_GUID_GLOBAL') as string,
    tracesSampleRate: 1,
    afdUrl: 'https://api-nonprod.christies.com.cn/uat',
    apimUrl: 'https://devapim.christies.com/uat',
  },
}

const getEnvironmentConfig = (): IEnvironmentConfig => {
  // Fail safe to production in case environment variables not provided.  Will get CORS errors running in other environments with this default.
  const christiesEnvironment = (
    env('NEXT_PUBLIC_CHRISTIES_ENVIRONMENT') || 'production'
  ).toUpperCase()

  const christiesRegion = (env('NEXT_PUBLIC_REGION_CONFIG') || 'EN').toUpperCase()

  const christiesEnvironmentAndRegion = [christiesEnvironment, christiesRegion].join('_')

  let result

  try {
    result = CHRISTIES_ENVIRONMENT_CONFIG[christiesEnvironmentAndRegion]
  } catch (e) {
    result = CHRISTIES_ENVIRONMENT_CONFIG.PRODUCTION_EN
  }

  // Don't emit this line if we're running under unit test conditions
  if (process.env.JEST_WORKER_ID === undefined) {
    // eslint-disable-next-line no-console
    console.info(
      `Operating in ${result.name}, environment variable was set to ${env(
        'NEXT_PUBLIC_CHRISTIES_ENVIRONMENT'
      )}, the build configuration is ${process.env.NODE_ENV} and the build ID is ${
        process.env.CONFIG_BUILD_ID
      }`
    )
  }

  return result
}

const environmentconfig = getEnvironmentConfig()

export const isProductionEnvironment = (): boolean =>
  environmentconfig ===
  (CHRISTIES_ENVIRONMENT_CONFIG.PRODUCTION_EN || CHRISTIES_ENVIRONMENT_CONFIG.PRODUCTION_CN)

export const isProductionBuild = (): boolean =>
  process.env.NODE_ENV === BUILD_CONFIGURATION.PRODUCTION

export default environmentconfig
