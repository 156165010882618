import * as Sentry from '@sentry/node'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { TokenType } from 'infrastructure/token'
import applicationToken from 'services/application-token.internal'
import clientToken from 'services/client-token'
import SentryTransaction from 'utils/sentry-transaction'
import SessionProvider from 'session'
import { resolveSentrySeverity } from 'utils/sentrySeverity'
import { resolveMulesoftSentryMessage } from 'utils/sentryMessage'
import { getRegion } from '../utils/getRegion'
import { environment } from 'configuration'

type ExtendedAxiosRequestConfig = AxiosRequestConfig & {
  tokenType?: TokenType
  hasRetried?: boolean
  endTransaction?: (httpStatus?: number) => void
  transactionName?: string
  service?: string
  customHeaders?: Record<string, string>
}

const SERVICE_NAME = 'Mulesoft'
const isRequestUnauthorized = (status: number | undefined) => status === 401
const hasAlreadyRetried = (requestConfig: ExtendedAxiosRequestConfig) =>
  requestConfig && requestConfig.hasRetried

const refreshApplicationTokenAndRetry = (
  axiosInstance: AxiosInstance,
  originalRequest: ExtendedAxiosRequestConfig
): Promise<AxiosResponse<any>> => {
  return applicationToken.refresh(axiosInstance).then(() => {
    // Mark the request as having been retried
    originalRequest.hasRetried = true

    const endTransaction = SentryTransaction.beginTransaction(originalRequest.transactionName, {
      data: {
        url: originalRequest.url,
        service: originalRequest.service,
      },
    })

    return axiosInstance(originalRequest).finally(endTransaction)
  })
}

const refreshUserTokenAndRetry = (
  axiosInstance: AxiosInstance,
  originalRequest: ExtendedAxiosRequestConfig
): Promise<AxiosResponse<any>> => {
  return clientToken.refresh(axiosInstance).then(() => {
    // Mark the request as having been retried
    originalRequest.hasRetried = true

    const endTransaction = SentryTransaction.beginTransaction(originalRequest.transactionName, {
      data: {
        url: originalRequest.url,
        service: originalRequest.service,
      },
    })

    return axiosInstance(originalRequest).finally(endTransaction)
  })
}

// See https://github.com/axios/axios#interceptors
// Intercept every request and make sure we're passing the right token through
const requestInterceptor = (config: ExtendedAxiosRequestConfig): AxiosRequestConfig => {
  let token

  if (config.tokenType === TokenType.Application) {
    token = SessionProvider.getApplicationSession()
  } else if (config.tokenType === TokenType.User) {
    token = SessionProvider.getUserSession().accessToken
  } else {
    token = ''
  }

  Sentry.addBreadcrumb({
    category: 'Account Creation',
    message: `Calling ${config.url} with ${
      config.tokenType === TokenType.Application ? 'an application' : 'a user'
    } token that is ${token ? 'defined' : 'undefined'}`,
    level: Sentry.Severity.Info,
  })

  config.headers = config.headers ?? {}

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  // use a different `Accept` header value when calling AFD service
  if (config.url?.startsWith(environment.afdUrl)) {
    config.headers['Accept'] = 'application/vnd.christies.v1+json'
  }

  if (config.customHeaders) {
    config.headers = Object.assign({}, config.headers, config.customHeaders)
  }

  return config
}

// This intercepts every response, and if we have had a response indicating that the token is not valid then
// we retry it.  If the retry fails, we just fail the whole request.
const responseErrorInterceptor =
  (axiosInstance: AxiosInstance) =>
  (err: AxiosError): Promise<AxiosResponse<any>> => {
    const originalRequest = err.config as ExtendedAxiosRequestConfig

    // We only care if the request was unauthorized, otherwise it's a standard server failure
    if (!isRequestUnauthorized(err.response?.status)) return Promise.reject(err)

    // If we've already retried this request then we don't want to get into an infinite loop, so we just reject
    if (hasAlreadyRetried(originalRequest)) return Promise.reject(err)

    originalRequest.endTransaction && originalRequest.endTransaction()

    return originalRequest.tokenType === TokenType.Application
      ? refreshApplicationTokenAndRetry(axiosInstance, originalRequest)
      : refreshUserTokenAndRetry(axiosInstance, originalRequest)
  }

const getAxiosInstance = () => {
  const axiosInstance = axios.create()

  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
  axiosInstance.defaults.headers.common['Accept'] = 'application/json'

  axiosInstance.interceptors.request.use(requestInterceptor)
  axiosInstance.interceptors.response.use((response) => {
    // If we've called the service successfully then slide the cookie forward
    // This is what would happen if they were calling the .com site and the server
    // was handling the cookies.
    SessionProvider.slideUserSessionExpiry()

    return response
  }, responseErrorInterceptor(axiosInstance))

  return axiosInstance
}

const mulesoftClient = getAxiosInstance()

type MulesoftError = {
  errors: [
    {
      Source: string
      type: string
      timestamp: string
      transactionId: string
      correlationId: string
      errorCode: number
      errorDescription: string
      innerError: string
    },
  ]
}

const trackSentry = (error: AxiosError<MulesoftError>, url: string, service = SERVICE_NAME) => {
  Sentry.addBreadcrumb({
    type: 'error',
    category: 'response inner error / description',
    message:
      error.response?.data?.errors?.[0]?.innerError ||
      error.response?.data.errors?.[0]?.errorDescription,
    level: Sentry.Severity.Error,
  })
  Sentry.captureEvent({
    message: resolveMulesoftSentryMessage(
      error.response?.status,
      error.response?.data?.errors?.[0]?.innerError,
      error.response?.data.errors?.[0]?.errorDescription
    ),
    level: resolveSentrySeverity(error.response?.status),
    tags: {
      service,
      endpoint: url.split('?')[0],
      transactionId: error.response?.data?.errors?.[0]?.transactionId,
      correlationId: error.response?.data?.errors?.[0]?.correlationId,
      whichend: process.env.NEXT_IS_SERVER === 'true' ? 'back' : 'front',
      region: getRegion(),
    },
  })
}

export interface MulesoftApiClient {
  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: ExtendedAxiosRequestConfig,
    transactionName?: string,
    service?: string
  ): Promise<R>
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: ExtendedAxiosRequestConfig,
    transactionName?: string,
    service?: string
  ): Promise<R>
}

export const mulesoftApiClient: MulesoftApiClient = {
  get: <T = any, R = AxiosResponse<T>>(
    url: string,
    config?: ExtendedAxiosRequestConfig,
    transactionName?: string,
    service = SERVICE_NAME
  ): Promise<R> => {
    const endTransaction = SentryTransaction.beginTransaction(transactionName, {
      data: {
        url,
        service,
      },
    })

    return mulesoftClient
      .get<T, R>(url, config)
      .catch((error: AxiosError<MulesoftError>) => {
        trackSentry(error, url, service)
        endTransaction(error.response?.data.errors[0].errorCode)
        throw error
      })
      .finally(endTransaction)
  },
  post: <T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: ExtendedAxiosRequestConfig,
    transactionName?: string,
    service = SERVICE_NAME
  ): Promise<R> => {
    const endTransaction = SentryTransaction.beginTransaction(transactionName, {
      data: {
        url,
        service,
      },
    })

    if (config) {
      config.endTransaction = endTransaction
      config.transactionName = transactionName
      config.service = service
    }

    return mulesoftClient
      .post<T, R>(url, data, config)
      .catch((error: AxiosError<MulesoftError>) => {
        trackSentry(error, url, service)
        endTransaction(error.response?.data.errors[0].errorCode)
        throw error
      })
      .finally(endTransaction)
  },
}
