/* eslint-disable no-console */
import * as Sentry from '@sentry/browser'
import { environment } from 'configuration'
import { ANALYTICS_CONFIG } from 'configuration/analytics'
import {
  LogEvent,
  LogForm,
  LogPage,
  LogTypes,
  TransactionAdobeEvent as AdobeEvent,
} from 'utils/logTypes'

class LogService {
  /**
   * --debug: flag to show output in console, can be enabled on any env using localstorage flag
   * --debugLink: flag allows you to disable links so you can see if the tracking works, uses localstorage flag
   *    CHR_LOGGING_DEBUG && CHR_LOGGING_DISABLE_LINK are the two localstorage keys used
   * --regionData: html attr to lookup region value. Use `data-region`
   */
  debug: boolean = environment.debug === 'true'
  debugLink = false
  isMobileApp = false

  constructor() {
    if (process.browser) {
      // LocalStorage overide for debug
      const debugFlag = localStorage.getItem(`${ANALYTICS_CONFIG.localStorageNamespace}_DEBUG`)
      if (debugFlag && debugFlag === 'true') this.debug = true

      // LocalStorage overide for debugging links
      const debugLinkFlag = localStorage.getItem(
        `${ANALYTICS_CONFIG.localStorageNamespace}_DISABLE_LINK`
      )
      if (debugLinkFlag && debugLinkFlag === 'true') this.debugLink = true
    }
  }

  private getRegion(el: HTMLElement) {
    return (
      el
        ?.closest(`[${ANALYTICS_CONFIG.regionAttribute}]`)
        ?.getAttribute(`${ANALYTICS_CONFIG.regionAttribute}`) || undefined
    )
  }

  public setDeviceType(isMobileApp: boolean): void {
    this.isMobileApp = isMobileApp
  }

  public trackPage(obj: LogPage): void {
    const { language, details, userStatus, userGuid } = obj

    if ((!language || !details) && this.debug) {
      console.warn('LOG SERVICE: page tracking missing required data', obj)
      return
    }

    const { page, step, onStart } = details

    const adobeObj: AdobeEvent = {
      events: {
        page_view: true,
      },
      page: {
        language,
        name: page,
        channel: ANALYTICS_CONFIG.channel,
      },
      // TODO: confirm if we need to send the GUID
      user: {
        ...(userStatus && { status: userStatus }),
        ...(userGuid && { GUID: userGuid }),
      },
      platform: {
        name: this.isMobileApp ? 'ios' : 'web',
        // Analytics - send the app version through, not currently passed from app in useragent so cannot immplimment
        version: 'not available',
      },
    }

    if (step) {
      adobeObj.form = { step }
    }

    if (onStart) {
      adobeObj.events = {
        [onStart]: true,
      }
    }

    this.sendEvent('page', adobeObj)
  }

  public trackForm(obj: LogForm): void {
    const { type, details, errors, attempt } = obj
    const { step, onSuccess } = details

    const adobeObj: AdobeEvent = {
      events: {
        interaction: true,
      },
      interaction: {
        type: type === 'error' ? 'form-errors' : 'form-success',
        location: 'form',
      },
      form: {
        step: step || 'undefined step',
        ...(attempt && { attempt }),
        ...(errors && { errors }),
      },
    }

    // Add custom event on success only
    if (adobeObj.events && type === 'success' && onSuccess) {
      adobeObj.events[onSuccess] = true
    }

    this.sendEvent('form', adobeObj)
  }

  public trackLink(
    e: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>,
    value: string
  ): boolean {
    e.preventDefault()

    const target = e.target as HTMLAnchorElement
    const adobeObj: AdobeEvent = {
      events: { interaction: true },
      interaction: {
        type: `link | ${value}`,
        location: this.getRegion(target) || 'unknown',
        destination: target.href,
      },
    }

    this.sendEvent('link', adobeObj)

    // When debugLink is true prevents redirect so we can debug log in console
    if (this.debugLink) {
      console.info('LOG SERVICE: debugLink enabled, link will not be followed')
      return false
    }

    document.location.href = target.href
    return false
  }

  public trackEvent(obj: LogEvent): void {
    const { region, el, value, action } = obj

    let mappedRegion = region

    if (!region && el) {
      mappedRegion = this.getRegion(el)
    }

    if (!region && !el && this.debug) {
      console.warn('LOG SERVICE: no location, element OR region value required')
    }

    const adobeObj: AdobeEvent = {
      events: {
        interaction: true,
      },
      interaction: {
        type: `${action} | ${value}`,
        location: mappedRegion || '',
      },
    }

    this.sendEvent('event', adobeObj)
  }

  private sendEvent(type: LogTypes, adobeObj: AdobeEvent) {
    // turn off whole of tracking for unit tests
    //@ts-ignore
    if (process.env.JEST_WORKER_ID === undefined && !window.Cypress) {
      try {
        if (this.debug) {
          console.info(`LOG SERVICE: send ${type}`, adobeObj)
        }

        if (!process.browser || typeof window === 'undefined') {
          console.warn('LOG SERVICE: no window object, cannot log')
          return
        }

        /** First page view needs to set on datalayer */
        if (!window.AnalyticsDataLayer || !window._trackData) {
          if (this.debug)
            console.info('LOG SERVICE: _trackData not available, putting on dataLayer')
          window.AnalyticsDataLayer = adobeObj
          return
        }

        // Send event to adobe
        window._trackData(adobeObj)
      } catch (error) {
        console.warn('LOG SERVICE: failed to log event', error)
        Sentry.captureException(error, {
          tags: {
            whichend: process.env.NEXT_IS_SERVER === 'true' ? 'back' : 'front',
          },
        })
      }
    }
  }
}

export const logService = new LogService()
