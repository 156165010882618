import React, { CSSProperties, ReactElement } from 'react'

import styles from './index.module.scss'
import { Icon } from 'components/Icon'
import { Icons } from 'components/Icon/icons'
import classNames from 'classnames'

export interface IButtonProps {
  buttonText: string
  disabled?: boolean
  onClick?: () => void
  className?: string
  type?: 'submit' | 'button' | 'reset'
  style?: CSSProperties
  loading?: boolean
  inverted?: boolean
  withPaperclipIcon?: boolean
  withLoadingIcon?: boolean
  testId?: string
  icon?: Icons
  link?: boolean
  ariaDescribedBy?: string
}

export const Button = React.forwardRef(
  (
    {
      buttonText,
      disabled,
      onClick,
      className = '',
      type = 'button',
      style,
      loading,
      inverted,
      withPaperclipIcon,
      withLoadingIcon,
      testId,
      icon,
      link,
      ariaDescribedBy,
    }: IButtonProps,
    ref: any
  ): ReactElement => {
    let iconToRender!: Icons

    if (withLoadingIcon) {
      iconToRender = 'loading'
    } else if (withPaperclipIcon) {
      iconToRender = 'paperclip'
    } else if (icon) {
      iconToRender = icon
    }

    return (
      <button
        ref={ref}
        style={style}
        data-testid={testId || 'button-primary'}
        type={type || 'button'}
        className={classNames(styles.button, link ? 'chr-action' : 'chr-action-bold', className, {
          [styles.loading]: loading,
          [styles.inverted]: inverted,
          [styles.link]: link,
          [styles.icon]: link && icon,
        })}
        onClick={() => {
          if (!loading && !disabled && onClick) {
            onClick()
          }
        }}
        disabled={disabled}
        aria-describedby={ariaDescribedBy}
      >
        {iconToRender && (
          <Icon
            icon={iconToRender}
            variant={disabled || inverted || icon ? 'dark' : 'light'}
            className={classNames(styles.button__icon, {
              [styles.xs]: icon,
            })}
          />
        )}
        <span>{buttonText}</span>
      </button>
    )
  }
)

Button.displayName = 'button'
