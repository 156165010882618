import { ACTION_TYPE, ReferrerAction } from 'actions/referrer'
import { environment } from 'configuration'
import { decodeBase64, isChristiesUrl, isThisApplication } from 'utils/urls'

export type ReferrerState = {
  url: string
}

const initialState = {
  url: environment.dotComUrl,
}

const determineUrl = (prevReferrer: string, nextReferrer: string): string => {
  if (isChristiesUrl(nextReferrer)) {
    return nextReferrer
  }

  if (isThisApplication(nextReferrer)) {
    return prevReferrer
  }

  const decodedUrl = decodeBase64(nextReferrer)
  if (isChristiesUrl(decodedUrl)) {
    return decodedUrl
  }

  return prevReferrer
}

export const referrerReducer = (state = initialState, action: ReferrerAction): ReferrerState => {
  switch (action.type) {
    case ACTION_TYPE.SET_REFERRER:
      return {
        ...state,
        url: determineUrl(state.url, action.payload),
      }
    default:
      return state
  }
}
